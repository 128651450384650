import { defineMessages } from 'react-intl'

export default defineMessages({
  setupHomePageTitle: {
    id: 'WeightLoss.SetupHomePage.pageTitle',
    defaultMessage: 'Weight loss',
  },
  setupHomePageHeading: {
    id: 'WeightLoss.SetupHomePage.heading',
    defaultMessage: 'Welcome to your weight loss programme',
  },
  setupHomePageDescription: {
    id: 'WeightLoss.SetupHomePage.description',
    defaultMessage:
      'Enter your details to check your eligibility and sign up for our weight loss programme, combining weight loss medication with a holistic programme.',
  },
  setupHomePageDescription2: {
    id: 'WeightLoss.SetupHomePage.description2',
    defaultMessage:
      'We give you all the care and support you need each week, easily accessed from your own home.',
  },
  setupHomePageDescription3: {
    id: 'WeightLoss.SetupHomePage.description3',
    defaultMessage:
      'If eligible, you will be connected directly to one of our healthcare technicians to begin your programme. Please make sure you are in a private location and have scales available to weigh yourself.',
  },
  setupHomePageDescription4: {
    id: 'WeightLoss.SetupHomePage.description4',
    defaultMessage:
      'The technician will need to see your scales so we recommend using a mobile device, such as a phone or tablet.',
  },
  setupHomePageImage: {
    id: 'WeightLoss.SetupHomePage.image',
    defaultMessage: 'Person standing on scale',
  },
  setupHomePageCta1: {
    id: 'WeightLoss.SetupHomePage.cta1',
    defaultMessage: 'Get started',
  },
  setupHomePageFootnote: {
    id: 'WeightLoss.SetupHomePage.footnote',
    defaultMessage:
      'Wegovy® and Mounjaro® are MHRA approved for weight management alongside a reduced calorie diet & increased physical activity.',
  },
  setupHomePagePrivacy: {
    id: 'WeightLoss.SetupHomePage.privacy',
    defaultMessage:
      'Your data will be processed in line with our updated <a>privacy policy</a>.',
  },
  setupQuestionnairePageTitle: {
    id: 'WeightLoss.SetupQuestionnairePage.pageTitle',
    defaultMessage: 'Weight loss - Questionnaire',
  },
  setupNextStepsPageTitle: {
    id: 'WeightLoss.SetupNextStepsPage.pageTitle',
    defaultMessage: 'Weight loss - One more step',
  },
  setupNextStepsPageHeading: {
    id: 'WeightLoss.SetupNextStepsPage.heading',
    defaultMessage: 'Final step before prescription',
  },
  setupNextStepsPageDescription1: {
    id: 'WeightLoss.SetupNextStepsPage.description1',
    defaultMessage:
      'We have a duty of care to ensure the safety of every eMed weight loss member. That is why we now need you to confirm your ID and weight.',
  },
  setupNextStepsPageDescription2: {
    id: 'WeightLoss.SetupNextStepsPage.description2',
    defaultMessage: 'Please ensure you have access to:',
  },
  setupNextStepsPageRequirement1: {
    id: 'WeightLoss.SetupNextStepsPage.requirement1',
    defaultMessage: 'Photo ID (passport or driving license)',
  },
  setupNextStepsPageRequirement2: {
    id: 'WeightLoss.SetupNextStepsPage.requirement2',
    defaultMessage: 'Scales',
  },
  setupNextStepsPageImageDescription: {
    id: 'WeightLoss.SetupNextStepsPage.imageDescription',
    defaultMessage: 'Never leave home',
  },
  setupNextStepsPageCta: {
    id: 'WeightLoss.SetupNextStepsPage.cta',
    defaultMessage: 'Verify ID and weight',
  },
  setupNextStepsPageFooterTitle: {
    id: 'WeightLoss.SetupNextStepsPage.footerTitle',
    defaultMessage: "Can't talk right now?",
  },
  setupNextStepsPageFooter: {
    id: 'WeightLoss.SetupNextStepsPage.footer',
    defaultMessage:
      "If you don't have your ID or weighing scales ready, you can do this later. You will now be emailed a link to complete this step within the next 24 hours.",
  },
  setupNextStepsPagePhoneDescription: {
    id: 'WeightLoss.SetupNextStepsPage.phoneDescription',
    defaultMessage:
      'Our clinicians may reach out to you with questions regarding your application.',
  },
  setupConfirmationPageTitle: {
    id: 'WeightLoss.SetupConfirmationPage.pageTitle',
    defaultMessage: 'Weight loss - Confirmation',
  },
  setupConfirmationPageHeading: {
    id: 'WeightLoss.SetupConfirmationPage.heading',
    defaultMessage: "You're all done!",
  },
  setupConfirmationPageDescription: {
    id: 'WeightLoss.SetupConfirmationPage.description',
    defaultMessage:
      "Congratulations, you have completed your application and it's now been submitted.",
  },
  setupConfirmationPageMedicalCardTitle: {
    id: 'WeightLoss.SetupConfirmationPage.medicalCard.title',
    defaultMessage: 'What happens next?',
  },
  setupConfirmationPageMedicalCardDescription: {
    id: 'WeightLoss.SetupConfirmationPage.medicalCard.description',
    defaultMessage:
      "Our doctors are now reviewing your information in accordance with our healthcare guidelines. We'll contact you within one working day to confirm if you're able to begin the eMed Weight Loss Programme.",
  },
  setupConfirmationPageMedicalCardImageDescription: {
    id: 'WeightLoss.SetupConfirmationPage.medicalCard.imageDescription',
    defaultMessage: 'Onboard with a medical provider',
  },
  setupConfirmationPageMedicationCardTitle: {
    id: 'WeightLoss.SetupConfirmationPage.medicationCard.title',
    defaultMessage: 'Receiving your medication',
  },
  setupConfirmationPageMedicationCardDescription: {
    id: 'WeightLoss.SetupConfirmationPage.medicationCard.description',
    defaultMessage:
      'If eligible, you will be contacted by our pharmacy partner to arrange postal delivery of your medication.',
  },
  setupConfirmationPageMedicationCardImageDescription: {
    id: 'WeightLoss.SetupConfirmationPage.medicationCard.imageDescription',
    defaultMessage: 'Receiving your medication',
  },
  setupConfirmationPageWeightCardTitle: {
    id: 'WeightLoss.SetupConfirmationPage.weightCard.title',
    defaultMessage: 'Starting your weight loss journey',
  },
  setupConfirmationPageWeightCardDescription: {
    id: 'WeightLoss.SetupConfirmationPage.weightCard.description',
    defaultMessage:
      'We will support you through weekly guided check-ins with an eMed Healthcare Technician; they will ensure you are taking your medication correctly and support you on your journey.',
  },
  setupConfirmationPageWeightCardImageDescription: {
    id: 'WeightLoss.SetupConfirmationPage.weightCard.imageDescription',
    defaultMessage: 'Start losing weight',
  },
  setupConfirmationPageCta: {
    id: 'WeightLoss.SetupConfirmationPage.cta',
    defaultMessage: 'Done',
  },
  setupErrorIneligiblePageHeading: {
    id: 'WeightLoss.SetupErrorIneligiblePage.pageTitle',
    defaultMessage: 'Unfortunately we cannot offer you this programme',
  },
  setupErrorIneligiblePageDescription: {
    id: 'WeightLoss.SetupErrorIneligiblePage.description',
    defaultMessage:
      'Due to NHS rules around private services for NHS patients, we are unable to offer our Weight Management Programme to anyone who is registered with GP at Hand.',
  },
  checkInAutomatedWeightCheck: {
    id: 'WeightLoss.CheckIn.automatedWeightCheck',
    defaultMessage: 'It is time for your weekly check-in!',
  },
  checkInStartupHomePageTitle: {
    id: 'WeightLoss.CheckInStartupHomePage.pageTitle',
    defaultMessage: 'Weight loss - Check-in startup',
  },
  checkInStartupHomePageHeading: {
    id: 'WeightLoss.CheckInStartupHomePage.heading',
    defaultMessage: 'Get ready for your first injection',
  },
  checkInStartupHomePageDescription: {
    id: 'WeightLoss.CheckInStartupHomePage.description',
    defaultMessage:
      'It’s time for your first weight loss injection. Please follow the instructions below:',
  },
  checkInStartupHomePageCard1Title: {
    id: 'WeightLoss.CheckInStartupHomePage.Card1.title',
    defaultMessage: 'Wash your hands',
  },
  checkInStartupHomePageCard1Description: {
    id: 'WeightLoss.CheckInStartupHomePage.Card1.description',
    defaultMessage: 'Please wash your hands before you take your injection.',
  },
  checkInStartupHomePageCard1ImageDescription: {
    id: 'WeightLoss.CheckInStartupHomePage.Card1.imageDescription',
    defaultMessage: 'Hands with soap icon',
  },
  checkInStartupHomePageCard2Title: {
    id: 'WeightLoss.CheckInStartupHomePage.Card2.title',
    defaultMessage: 'Be prepared',
  },
  checkInStartupHomePageCard2Description: {
    id: 'WeightLoss.CheckInStartupHomePage.Card2.description',
    defaultMessage:
      'You will need to be in a private space and have access to:',
  },
  checkInStartupHomePageCard2ImageDescription: {
    id: 'WeightLoss.CheckInStartupHomePage.Card2.imageDescription',
    defaultMessage: 'Syringe icon',
  },
  checkInStartupHomePageCard2ListItem1: {
    id: 'WeightLoss.CheckInStartupHomePage.Card2.listItem1',
    defaultMessage: 'Your weight loss injection pen and needle',
  },
  checkInStartupHomePageCard2ListItem2: {
    id: 'WeightLoss.CheckInStartupHomePage.Card2.listItem2',
    defaultMessage: 'Sharps disposal bin',
  },
  checkInStartupHomePageCard2ListItem3: {
    id: 'WeightLoss.CheckInStartupHomePage.Card2.listItem3',
    defaultMessage: 'Scales',
  },
  checkInStartupHomePageCard2ListItem4: {
    id: 'WeightLoss.CheckInStartupHomePage.Card2.listItem4',
    defaultMessage: 'Mobile phone or another device with a built-in camera',
  },
  checkInStartupHomePagePostScript: {
    id: 'WeightLoss.CheckInStartupHomePage.postScript',
    defaultMessage:
      'Please activate your account by selecting your preferred check-in option below. You can add your information manually or have a live check-in with a member of the eMed Healthcare Team. ',
  },
  checkInStartupHomePageUnauthenticatedCta: {
    id: 'WeightLoss.CheckInStartupHomePage.unauthenticatedCta',
    defaultMessage: 'Get started',
  },
  checkInStartupHomePageProctorCta: {
    id: 'WeightLoss.CheckInStartupHomePage.proctorCta',
    defaultMessage: 'Live check-in',
  },
  checkInStartupHomePageNoProctorCta: {
    id: 'WeightLoss.CheckInStartupHomePage.noProctorCta',
    defaultMessage: 'Self check-in',
  },
  checkInStartupHomePageNoProctorDisabledCta: {
    id: 'WeightLoss.CheckInStartupHomePage.noProctorDisabledCta',
    defaultMessage: 'Start your activation session',
  },
  checkInPageDescription: {
    id: 'WeightLoss.CheckInPage.description',
    defaultMessage:
      'It’s time for your weekly check-in. Please follow the instructions below:',
  },
  checkInProctorCta: {
    id: 'WeightLoss.CheckInProctorCta.cta2',
    defaultMessage: 'Live check-in',
  },
  checkInNoProctorCta: {
    id: 'WeightLoss.CheckInNoProctorCta.cta3',
    defaultMessage: 'Self check-in',
  },
  checkInPagePostScript: {
    id: 'WeightLoss.CheckIn.postScript',
    defaultMessage:
      'Please select your preferred check-in option below. You can add your information manually or have a live check-in with a member of the eMed Healthcare Team.',
  },
  checkInStartupConfirmationPageTitle: {
    id: 'WeightLoss.CheckInStartupConfirmationPage.pageTitle',
    defaultMessage: 'Weight loss - Check-in startup confirmation',
  },
  checkInStartupConfirmationPageHeading: {
    id: 'WeightLoss.CheckInStartupConfirmationPage.heading',
    defaultMessage: "You're all done!",
  },
  checkInStartupConfirmationPageDescription: {
    id: 'WeightLoss.CheckInStartupConfirmationPage.description',
    defaultMessage:
      'Congratulations on your first injection. Your next check-in is in one week.',
  },
  checkInAutomatedWeightCheckTitle: {
    id: 'WeightLoss.CheckInAutomatedWeightCheck.title',
    defaultMessage: 'It is time for your weekly check-in!',
  },
  checkInAutomatedWeightCheckSubtitle: {
    id: 'WeightLoss.CheckInAutomatedWeightCheck.subtitle',
    defaultMessage: "First, let's record your latest weight",
  },
  checkInAutomatedInjectionTitle: {
    id: 'WeightLoss.CheckInAutomatedInjection.title',
    defaultMessage: 'Your weekly injection',
  },
  checkInHomePageTitle: {
    id: 'WeightLoss.CheckInHomePage.pageTitle',
    defaultMessage: 'Weight loss - Weekly check-in',
  },
  checkInHomePageHeading: {
    id: 'WeightLoss.CheckInHomePage.heading',
    defaultMessage: 'Get ready for your weekly check-in',
  },
  checkInConfirmationPageTitle: {
    id: 'WeightLoss.CheckInConfirmationPage.pageTitle',
    defaultMessage: 'Weight loss - Weekly check-in confirmation',
  },
  checkInConfirmationPageHeading: {
    id: 'WeightLoss.CheckInConfirmationPage.heading',
    defaultMessage: 'Weekly check-in done!',
  },
  checkInConfirmationPageDescription: {
    id: 'WeightLoss.CheckInConfirmationPage.description',
    defaultMessage: 'Your next check-in is in one week.',
  },
  checkoutDescription1: {
    id: 'WeightLoss.Checkout.description1',
    defaultMessage:
      'Thank you for completing your weight management screening questionnaire.',
  },
  checkoutDescription2: {
    id: 'WeightLoss.Checkout.description2',
    defaultMessage:
      'We will need your payment details to sign you up to your subscription.',
  },
  checkoutDescription3Delayed: {
    id: 'WeightLoss.Checkout.description3Delayed',
    defaultMessage:
      'Your subscription will start in 3 days to give you time to connect with an eMed Healthcare Technician to complete your application. If you are approved before this you will be charged and your programme will start immediately.*',
  },
  checkoutDescription3Immediate: {
    id: 'WeightLoss.Checkout.description3Immediate',
    defaultMessage:
      "Your subscription will start immediately. However, should our clinicians determine that this programme isn't right for you during the initial assessment, you will receive a full refund.",
  },
  checkoutPageSmallPrint: {
    id: 'WeightLoss.Checkout.smallPrint',
    defaultMessage:
      '* Please note your card will be charged after 3 days even if the application is not complete, if you decide not to proceed at this point and have not been through clinical review you will need to contact support for a refund.',
  },
  checkoutAfterDiscountCodeTermsAndConditions: {
    id: 'WeightLoss.Checkout.afterDiscountCodeTermsAndConditions',
    defaultMessage:
      '* Price includes discount. Please see our terms and conditions.',
  },
  checkoutMounjaroDisclaimer: {
    id: 'WeightLoss.Checkout.checkoutMounjaroDisclaimer',
    defaultMessage:
      '** If you use an oral contraceptive pill and want to start Mounjaro, you must use an additional barrier method of contraception (e.g. condoms) for four weeks from your first Mounjaro injection, and for four weeks after each time we adjust your dosage. If you do not wish to do this then we do not recommend Mounjaro as a treatment of choice.',
  },
  checkoutPageTermsAndConditions: {
    id: 'WeightLoss.Checkout.termsAndConditions',
    defaultMessage: '* Please see our terms and conditions.',
  },
  checkoutPageTitle: {
    id: 'WeightLoss.Checkout.Title',
    defaultMessage: 'Payment Details',
  },
  checkoutPageTitleV3: {
    id: 'WeightLoss.Checkout.TitleV3',
    defaultMessage: 'Your Plan',
  },
  checkoutPageHeading: {
    id: 'WeightLoss.Checkout.heading',
    defaultMessage: "Let's go!",
  },
  checkoutPageHeadingV3: {
    id: 'WeightLoss.Checkout.headingV3',
    defaultMessage: 'eMed ® Weight management programme',
  },
  checkoutSubmitError: {
    id: 'WeightLoss.Checkout.submitError',
    defaultMessage: 'Something went wrong, please try again',
  },
  checkoutPageSubHeading: {
    id: 'WeightLoss.Checkout.subHeading',
    defaultMessage: 'Choose your medication option',
  },
  checkoutPageError: {
    id: 'WeightLoss.Checkout.error',
    defaultMessage:
      'Order placement is temporarily unavailable. Please try again later.',
  },
  checkoutPageProductDescription1: {
    id: 'WeightLoss.Checkout.productDescription1',
    defaultMessage: 'Up to 20% weight loss',
  },
  checkoutPageProductDescription2: {
    id: 'WeightLoss.Checkout.productDescription2',
    defaultMessage: 'Cancel anytime',
  },
  checkoutPageProductDescription3: {
    id: 'WeightLoss.Checkout.productDescription3',
    defaultMessage: 'Free physiotherapy and mental health appointments',
  },
  checkoutPageProductDescription4: {
    id: 'WeightLoss.Checkout.productDescription4',
    defaultMessage: 'Trusted by 6 million customers',
  },
  checkoutPageDiscountCodeText1: {
    id: 'WeightLoss.Checkout.discountCodeText1',
    defaultMessage: 'Enter discount code',
  },
  checkoutPageDiscountCodeText2: {
    id: 'WeightLoss.Checkout.discountCodeText2',
    defaultMessage: 'at checkout for',
  },
  checkoutPageDiscountValidUntil: {
    id: 'WeightLoss.Checkout.discountValidUntil',
    defaultMessage: 'Valid until',
  },
  checkoutPageTitleCta: {
    id: 'WeightLoss.Checkout.cta',
    defaultMessage: 'Checkout',
  },
  checkoutPageProductDescription: {
    id: 'WeightLoss.Checkout.productDescription',
    defaultMessage:
      'Weekly weight loss medication injection with a personalised prescription tailored to your goals along with weekly check-ins.',
  },
  checkoutDiscountDescription: {
    id: 'WeightLoss.Checkout.discountDescription',
    defaultMessage: 'First month discount',
  },
  checkoutSubtotal: {
    id: 'WeightLoss.Checkout.subtotal',
    defaultMessage: 'Subtotal',
  },
  checkoutTotal: {
    id: 'WeightLoss.Checkout.total',
    defaultMessage: 'Total',
  },
  checkoutDelivery: {
    id: 'WeightLoss.Checkout.delivery',
    defaultMessage: 'Delivery',
  },
  checkoutFree: {
    id: 'WeightLoss.Checkout.free',
    defaultMessage: 'FREE',
  },
  checkoutRecommended: {
    id: 'WeightLoss.Checkout.recommended',
    defaultMessage: 'Recommended',
  },
  checkoutProductTableHeading: {
    id: 'WeightLoss.Checkout.productTableHeading',
    defaultMessage: 'What’s included',
  },
  checkoutPageProductTitle: {
    id: 'WeightLoss.Checkout.productTitle',
    defaultMessage: 'eMed® Weight Management Programme',
  },
  checkoutPageProductPrice: {
    id: 'WeightLoss.Checkout.productPrice',
    defaultMessage: 'per month*',
  },
  gpDetailsNeeded: {
    id: 'WeightLoss.GpDetails.needed',
    defaultMessage:
      'We need to confirm your GP details for continuity of care.',
  },
  refillCheckInHomePageTitle: {
    id: 'WeightLoss.RefillCheckInHomePage.pageTitle',
    defaultMessage: 'Weight loss - Monthly Refill',
  },
  refillCheckInHomePageHeading: {
    id: 'WeightLoss.RefillCheckInHomePage.heading',
    defaultMessage: 'Get your monthly refill',
  },
  refillCheckInHomePageDescription: {
    id: 'WeightLoss.RefillCheckInHomePage.description',
    defaultMessage: 'Well done on your weight loss journey so far!',
  },
  refillCheckInHomePageDescription2: {
    id: 'WeightLoss.RefillCheckInHomePage.description2',
    defaultMessage:
      'We are keen to assess your progress and ensure that your treatment remains tailored to your needs. Please answer a few questions so we can issue your refill prescription. This should only take five minutes.',
  },
  refillCheckInHomePageCta: {
    id: 'WeightLoss.RefillCheckInHomePage.cta',
    defaultMessage: 'Start refill questionnaire',
  },
  refillHomePageTitle: {
    id: 'WeightLoss.RefillHomePage.pageTitle',
    defaultMessage: 'Weight loss - Monthly Refill Questionnaire',
  },
  refillHomePageHeading: {
    id: 'WeightLoss.RefillHomePage.heading',
    defaultMessage: 'Welcome to your monthly refill',
  },
  refillHomePageDescription: {
    id: 'WeightLoss.RefillHomePage.description',
    defaultMessage:
      'We will ask a few questions so that our clinicians can safely prescribe a new weight loss pen.',
  },
  refillHomePageCta: {
    id: 'WeightLoss.refillHomePage.cta',
    defaultMessage: 'Next',
  },
  refillQuestionnairePageTitle: {
    id: 'WeightLoss.RefillQuestionnairePage.pageTitle',
    defaultMessage: 'Weight loss - Monthly check-in questionnaire',
  },
  refillQuestionnairePageHeading: {
    id: 'WeightLoss.RefillQuestionnairePage.heading',
    defaultMessage: 'Questionnaire',
  },
  refillNextStepsPageCta: {
    id: 'WeightLoss.RefillNextStepsPage.cta',
    defaultMessage: 'Talk to an eMed Healthcare Technician',
  },
  refillNextStepsPageTitle: {
    id: 'WeightLoss.RefillNextStepsPage.pageTitle',
    defaultMessage: 'Weight loss - Monthly check-in final step',
  },
  refillNextStepsPageHeading: {
    id: 'WeightLoss.RefillNextStepsPage.heading',
    defaultMessage: 'One more step...',
  },
  refillNextStepsPageDescription: {
    id: 'WeightLoss.refillPage.description1',
    defaultMessage:
      'Thank you for completing our weight management refill questionnaire. Get ready for your monthly check-in. Here are some tips to make it easy:',
  },
  refillConfirmationPageTitle: {
    id: 'WeightLoss.RefillConfirmationPage.pageTitle',
    defaultMessage: 'Weight loss - Refill questionnaire complete',
  },
  refillConfirmationPageHeading: {
    id: 'WeightLoss.RefillConfirmationPage.heading',
    defaultMessage: 'Thanks!',
  },
  refillConfirmationPageDescription: {
    id: 'WeightLoss.RefillConfirmationPage.description',
    defaultMessage:
      'Our clinicians will now review your responses and create your personalised prescription.',
  },
  refillCheckInConfirmationPageTitle: {
    id: 'WeightLoss.RefillCheckInConfirmationPage.pageTitle',
    defaultMessage: 'Weight loss - Monthly check-in confirmation',
  },
  refillCheckInConfirmationPageHeading: {
    id: 'WeightLoss.RefillCheckInConfirmationPage.heading',
    defaultMessage: 'Monthly check-in done!',
  },
  dashboardPageTitle: {
    id: 'WeightLoss.Dashboard.pageTitle',
    defaultMessage: 'Weight loss - Dashboard',
  },
  dashboardPageHeading: {
    id: 'WeightLoss.Dashboard.heading',
    defaultMessage: 'Your progress report',
  },
  dashboardImperial: {
    id: 'WeightLoss.Dashboard.imperial',
    defaultMessage: 'Switch to st, lb',
  },
  dashboardMetric: {
    id: 'WeightLoss.Dashboard.metric',
    defaultMessage: 'Switch to kg',
  },
  dashboardHeader1TotalLost: {
    id: 'WeightLoss.Dashboard.header1TotalLost',
    defaultMessage: 'Total lost',
  },
  dashboardHeader1Changed: {
    id: 'WeightLoss.Dashboard.header1Changed',
    defaultMessage: 'Total change',
  },
  dashboardHeader2: {
    id: 'WeightLoss.Dashboard.header2',
    defaultMessage: 'Current weight',
  },
  dashboardHeader3: {
    id: 'WeightLoss.Dashboard.header3',
    defaultMessage: 'This week',
  },
  dashboardCheckIns: {
    id: 'WeightLoss.Dashboard.checkIns',
    defaultMessage: 'Your Check-ins',
  },
  dashboardEmptyState: {
    id: 'WeightLoss.Dashboard.emptyState',
    defaultMessage: 'You have no check-ins yet',
  },
  dashboardEmptyStateDescription: {
    id: 'WeightLoss.Dashboard.emptyStateDescription',
    defaultMessage:
      "You'll be able to track your progress and see each of your check-ins here once you have started your programme.",
  },
  dashboardError: {
    id: 'WeightLoss.Dashboard.error',
    defaultMessage: 'Something went wrong, please try again later.',
  },
  dashboardManageSubscriptionTitle: {
    id: 'WeightLoss.Dashboard.manageSubscriptionTitle',
    defaultMessage: 'Manage Subscription',
  },
})
