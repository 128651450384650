import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames/bind'
import { useFormatMessage } from '@babylon/intl'
import {
  useWeightLossCheckInHomeInfo,
  useWeightLossNoProctorStartupEnabled,
} from '@/redux/selectors'
import { login as loginAction } from '@/components/Authentication/actions'
import WeightLossSyringeImage from '@/assets/images/weight-loss_syringe.png'
import WeightLossHandsImage from '@/assets/images/weight-loss_hands.png'
import Button from '@/programmes/components/Button'
import Template from '../Template'
import Announcement from '@/programmes/components/Announcement'
import styles from '../index.module.scss'
import { procedures } from '../routes'
import useGetProctorSessionInvite from '@/programmes/hooks/useGetProctorSessionInvite'
import messages from '../messages'
import Card from '@/programmes/components/Card'

const cx = classNames.bind(styles)

const CheckInStartupHomePage = () => {
  const t = useFormatMessage()
  const dispatch = useDispatch()
  const announcement = useWeightLossCheckInHomeInfo()
  const weightLossNoProctorStartupEnabled = useWeightLossNoProctorStartupEnabled()
  const isWeightLossNoProctorStartupEnabledFetched =
    weightLossNoProctorStartupEnabled !== undefined
  const {
    getProctorSessionInvite,
    data,
    loading,
  } = useGetProctorSessionInvite()
  const isAuthenticated = useSelector((state: any) => state.auth?.user?.id)
  const proctorInviteUrl = data?.createProctorSessionInvite?.url
  const [selectedProcedure, setSelectedProcedure] = React.useState('')

  if (proctorInviteUrl) {
    window.location.href = proctorInviteUrl
  }

  const handleClick = (procedureName) => {
    if (isAuthenticated) {
      return getProctorSessionInvite({
        variables: {
          input: {
            procedure: {
              identifier: { value: procedureName },
            },
          },
        },
      })
    }

    return dispatch(loginAction())
  }

  const handleNoProctorClick = () => {
    setSelectedProcedure(procedures.WEIGHT_LOSS_CHECK_IN_STARTUP_NO_PROCTOR)
    handleClick(procedures.WEIGHT_LOSS_CHECK_IN_STARTUP_NO_PROCTOR)
  }

  const handleProctorClick = () => {
    setSelectedProcedure(procedures.WEIGHT_LOSS_CHECK_IN_STARTUP)
    handleClick(procedures.WEIGHT_LOSS_CHECK_IN_STARTUP)
  }

  const startSessionButtonText = () => {
    if (isAuthenticated && weightLossNoProctorStartupEnabled) {
      return messages.checkInStartupHomePageProctorCta
    }

    if (isAuthenticated) {
      return messages.checkInStartupHomePageNoProctorDisabledCta
    }

    return messages.checkInStartupHomePageUnauthenticatedCta
  }

  return (
    <Template
      title={t(messages.checkInStartupHomePageTitle)}
      hasAppLayout={isAuthenticated}
    >
      <h2>{t(messages.checkInStartupHomePageHeading)}</h2>
      <p>{t(messages.checkInStartupHomePageDescription)}</p>
      <div className={cx('check-in-startup-cards')}>
        <Card
          title={t(messages.checkInStartupHomePageCard1Title)}
          description={t(messages.checkInStartupHomePageCard1Description)}
          image={WeightLossHandsImage}
          imageDescription={t(
            messages.checkInStartupHomePageCard1ImageDescription
          )}
        />
        <Card
          title={t(messages.checkInStartupHomePageCard2Title)}
          description={t(messages.checkInStartupHomePageCard2Description)}
          image={WeightLossSyringeImage}
          imageDescription={t(
            messages.checkInStartupHomePageCard2ImageDescription
          )}
        >
          <ul className={cx('bulleted-list')}>
            <li className={cx('bulleted-list-item')}>
              {t(messages.checkInStartupHomePageCard2ListItem1)}
            </li>
            <li className={cx('bulleted-list-item')}>
              {t(messages.checkInStartupHomePageCard2ListItem2)}
            </li>
            <li className={cx('bulleted-list-item')}>
              {t(messages.checkInStartupHomePageCard2ListItem3)}
            </li>
            <li className={cx('bulleted-list-item')}>
              {t(messages.checkInStartupHomePageCard2ListItem4)}
            </li>
          </ul>
        </Card>
        {isAuthenticated && weightLossNoProctorStartupEnabled && (
          <p>{t(messages.checkInStartupHomePagePostScript)}</p>
        )}
      </div>

      {announcement && <Announcement content={announcement} />}
      {(!isAuthenticated || isWeightLossNoProctorStartupEnabledFetched) && (
        <>
          <Button
            onClick={handleProctorClick}
            loading={
              selectedProcedure === procedures.WEIGHT_LOSS_CHECK_IN_STARTUP &&
              (loading || !!proctorInviteUrl)
            }
          >
            {t(startSessionButtonText())}
          </Button>

          {isAuthenticated && weightLossNoProctorStartupEnabled && (
            <Button
              secondary
              onClick={handleNoProctorClick}
              loading={
                selectedProcedure ===
                  procedures.WEIGHT_LOSS_CHECK_IN_STARTUP_NO_PROCTOR &&
                (loading || !!proctorInviteUrl)
              }
            >
              {t(messages.checkInStartupHomePageNoProctorCta)}
            </Button>
          )}
        </>
      )}
    </Template>
  )
}

export default CheckInStartupHomePage
