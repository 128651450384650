import { useSelector } from 'react-redux'
import {
  selectShowPromotionModal,
  selectPromocode,
} from '@/components/Authentication/Register/selectors'
import {
  selectInCall,
  selectIncomingCall,
} from '@/components/VideoCall/selectors'
import { InitialState as BookingInvitationType } from '@/components/Appointments/BookingInvitation/reducer'
import { ReduxState } from './types'
import { AppointmentBookingFlowsConfig } from './productConfigReducer/productConfigTypes'

interface BookingFlowProductConfig {
  enabled: boolean
  showFilters: boolean
}

interface InviteBookingFlowProductConfig {
  clinicianTypeEnabled: boolean
}

interface BookingFlowsProductConfig {
  serviceMix: BookingFlowProductConfig
  invite: InviteBookingFlowProductConfig
}

const useRemoteConfigLoaded = () =>
  useSelector<any, boolean>((state) => !!state.config.remoteConfigLoaded)

const useFacebookEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.facebookLogin)

const useAppointmentsCovidBannerEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.appointment?.booking?.coronavirusBannerEnabled
  )

const useBookAppointmentEnabled = () =>
  useSelector<any, boolean>(selectBookAppointmentsEnabled)

const selectInviteBookingFlowConfig = (
  state
): undefined | BookingFlowsProductConfig['invite'] =>
  state.config.appointment?.booking?.bookingFlows?.invite

const useInviteBookingFlowConfig = () =>
  useSelector<any, undefined | BookingFlowsProductConfig['invite']>(
    selectInviteBookingFlowConfig
  )

const selectBookingInvitation = (state): BookingInvitationType =>
  state.bookingInvitation

const useServiceMixBookingFlowConfig = () =>
  useSelector<any, undefined | BookingFlowProductConfig>(
    (state) => state.config.appointment?.booking?.bookingFlows?.serviceMix
  )

const useServiceMixBookingFlowEnabled = () =>
  useSelector<any, undefined | BookingFlowProductConfig>(
    (state) =>
      state.config.appointment?.booking?.bookingFlows?.serviceMix?.enabled ||
      state.config.bookingFlowNvlEnabled // TODO: remove this or statement once new config is live
  )

const useServiceMixBookingFlowFiltersEnabled = () =>
  useSelector<any, undefined | BookingFlowProductConfig>(
    (state) =>
      state.config.appointment?.booking?.bookingFlows?.serviceMix
        ?.filtersEnabled
  )

const useClientCompatibleFlowsCheckInEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.clientCompatibleFlowsCheckInEnabled
  )

const useClientCompatibleFlowsRtmEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.clientCompatibleFlowsRtmEnabled
  )

const useLocationCheckEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.locationCheckEnabled)

const useMultipleImagesBooking = () =>
  useSelector<any, number>(
    (state) => state.config.appointment?.booking?.multipleImages ?? -1
  )

const useVideoCallWithToken = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.videoAppointmentsWithToken
  )

const useNotificationsEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.notifications)

const useCustomerSupportEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.customerSupport)

const useMarketingEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.marketing)

const useFeedbackEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.footer?.feedbackEnabled)

const useFeatureFlags = () =>
  useSelector<any, any>((state) => state.featureFlags)

const useDownloadAppEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.downloadApp)

const useReferAFriendEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.referAFriend)

const selectFamilyEnabled = (state: any) => !!state.config.family

const useFamilyEnabled = () => useSelector<any, boolean>(selectFamilyEnabled)

const useMembershipPlansEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.membershipPlans)

const usePaymentEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.payment)

const useChatbotEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.chatbot)

const useWelcomePageUpgradeButtonEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config?.welcomePage?.upgradeButton
  )

const useWelcomePageWeightManagementButtonEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config?.welcomePage?.weightManagementButton
  )

const useWelcomePageBookInMinutesButtonEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config?.welcomePage?.bookInMinutesButton
  )

const useWelcomePageBookInMinutesMaxWaitTime = () =>
  useSelector<any, number>(
    (state) => state.config?.welcomePage?.bookInMinutesMaxWaitTime
  )

const useWelcomePageDiscoverSectionEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config?.welcomePage?.discoverSectionEnabled
  )

const usePersonalDetailsEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.personalDetailsOptions?.enabled
  )

const usePersonalDetailsAddressEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.personalDetailsOptions?.addressEnabled
  )

const useAllergiesEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.allergies)

const useAppointmentsEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.appointment?.enabled || state.config.appointments
  ) // TODO: remove this or statement once new config is live

const useGpDetailsEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.gpDetails)

const useMedicalHistoryEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.medicalHistory)

const useMedicationsEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.medications)

const usePharmaciesEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.pharmacies)

const usePrescriptionsEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.prescriptions)

const useTestAndKitsEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.testAndKits)

const useIsAutomatedCheckInEnabledForScreen = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.emedScreen?.automatedCheckInEnabled
  )

const useIsWeightLossAutomatedCheckInEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.weightLossProgramme?.automatedCheckInEnabled
  )

const useChatHistoryEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.chatHistory)

const useReferralPdfDownload = () =>
  useSelector<any, boolean>((state) => !!state.config.referralPdfDownload)

const useBabylonBrandingEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.babylonBrandApp)

const useEnvironment = () => useSelector<any, any>(selectEnvironment)

const selectEnvironment = (state) => state.env

interface Auth0Config {
  clientId: string
  domain: string
}

const useAuth0Config = () =>
  useSelector<any, Auth0Config>((state) => state.config.auth0)

const useIdVerificationEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.idVerification)

const usePrivacyPolicyEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.footer?.privacyPolicy)

const useFooterCookiesEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.footer?.cookies)

const useCookiesEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.cookies)

const useTermsAndConditionsEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.footer?.termsAndConditions
  )

const useRegulatoryEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.footer?.regulatory)

const usePartnerLogo = () =>
  useSelector<any, boolean>((state) => state.config.footer?.partnerLogo)

const useCookiePolicyLink = () =>
  useSelector<any, string>((state) => state.config.cookiePolicyLink)

const useRegulatoryPolicyLink = () =>
  useSelector<any, string>((state) => state.config.regulatoryPolicyLink)

const useServiceUrl = () =>
  useSelector<any, string>((state) => state.env.MICROSERVICES_URL)

const useCurrentUser = () => useSelector<any, any>((state) => state.auth.user)

const useCurrentUserId = () =>
  useSelector<any, string | null>((state) => state.auth.user?.id)

const useSupportPhoneNumber = () =>
  useSelector<any, any>((state) => state.config.supportPhoneNumber)

const useMissingProfileFields = () =>
  useSelector<any, any>((state) => state.config.askForMissingProfileFields)

const useAppTitle = () =>
  useSelector<any, string>((state) => state.config.appTitle) || 'eMed'

const useAppName = () => useSelector<any, string>(selectAppName)

const selectAppName = (state) => state.config.appName || 'babylon'

const usePoweredByEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.footer?.poweredByEnabled)

const selectLogo = (state: any): string => state.config.logo

const useLogo = () => useSelector<any, string>(selectLogo)

type Registration = {
  phoneNumber: boolean
}

const useRegistrationFields = () =>
  useSelector<any, Registration>((state) => state.config.registration)

const useHealthcareIdentifierModalShown = () =>
  useSelector<any, boolean>((state) => !!state.healthcareIdentifierModalShown)

const useRegisterShowPromotionModal = () =>
  useSelector<any, boolean>(selectShowPromotionModal)
const useRegisterPromocode = () => useSelector<any, string>(selectPromocode)

const selectBookAppointmentsEnabled = (state: any) =>
  !!state.config.appointment?.booking?.enabled || state.config.bookAppointments // TODO: remove this or statement once new config is live

const selectLocalisation = (state) => state.config.localisation
const selectDefaultLanguage = (state) =>
  selectLocalisation(state).language || 'en-GB'

const useDefaultLanguage = () => useSelector<any, string>(selectDefaultLanguage)

const useLocalisation = () => useSelector<any, any>(selectLocalisation)

const useDefaultRegion = () => useLocalisation().region || 'GBR'

const useDismissInvite = () =>
  useSelector<any, boolean>((state) => !!state.config.dismissInviteEnabled)

const useOnboarding = () =>
  useSelector<any, any>((state) => state.config.onboarding)

const useOnboardingRedirects = () =>
  useSelector<any, any>((state) => state.config.onboarding.redirects)

interface AppointmentProductConfig {
  recordingsLinkEnabled: boolean
  unavailableRecordingsTextEnabled: boolean
  sickNoteActionEnabled: boolean
  triggerDocumentDownloadInExistingTabEnabled: boolean
}

const useAppointmentConfig = () =>
  useSelector<any, undefined | AppointmentProductConfig>(
    (state) => state.config.appointment
  )

const useTriggerDocumentDownloadInExistingTab = () =>
  useSelector<any, boolean>(
    (state) =>
      !!state.config.appointment?.triggerDocumentDownloadInExistingTabEnabled
  )

const useIncomingCall = () => useSelector<any, boolean>(selectIncomingCall)

const useInCall = () => useSelector<any, boolean>(selectInCall)

const useUnderMaintenance = () =>
  useSelector<any, boolean>((state) => state.config.underMaintenance)

const useHealthInformationEnabled = () =>
  useSelector<any, boolean>((state) => state.config.healthInformation)

const useHealthInformationLink = () =>
  useSelector<any, string>((state) => state.config.healthInformationLink)

const useWebSignupUrl = () =>
  useSelector<any, string>((state) => state.config.app.webSignupUrl)

const useAppUrl = () =>
  useSelector<any, string>((state) => state.config.app.appUrl)

const useFamilyMemberUrl = () =>
  useSelector<any, string>((state) => state.config.familyMemberUrl)

const useGpahRegistrationRestricted = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.gpahRegistrationRestricted
  )

const useGpahRegistrationRestrictedCity = () =>
  useSelector<any, string>(
    (state) => state.config.gpahRegistrationRestrictedCity || ''
  )

const useGpahRegistrationRestrictedDistricts = () =>
  useSelector<any, string>(
    (state) => state.config.gpahRegistrationRestrictedDistricts || ''
  )

const useGpahRegistrationRestrictedPostcodePrefixes = () =>
  useSelector<any, string[]>((state) =>
    state.config.gpahRegistrationRestrictedPostcodePrefixes
      ? JSON.parse(state.config.gpahRegistrationRestrictedPostcodePrefixes)
      : []
  )

const useBloodTestSetupNextStepsInfo = () =>
  useSelector<any, string>((state) => state.config.bloodTestSetupNextStepsInfo)

const useWeightLossNoProctorEnabled = () =>
  useSelector<any, boolean>((state) => state.config.weightLossNoProctorEnabled)

const useWeightLossNoProctorStartupEnabled = () =>
  useSelector<any, boolean>(
    (state) => state.config.weightLossNoProctorStartupEnabled
  )

const useWeightLossSetupEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.weightLossSetupEnabled)

const useWeightLossImmediatePayment = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.weightLossImmediatePayment
  )

const useWeightLossPrice = () =>
  useSelector<any, string | undefined>(
    (state) => state.config.weightLossPrice || '£249'
  )

const useWeightLossDiscountCode = () =>
  useSelector<any, string | undefined>(
    (state) => state.config.weightLossDiscount?.code
  )

const useWeightLossDiscountCodeValidUntil = () =>
  useSelector<any, string | undefined>(
    (state) => state.config.weightLossDiscount?.validUntil
  )

const useWeightLossDiscountAmount = () =>
  useSelector<any, string | undefined>(
    (state) => state.config.weightLossDiscount?.amount
  )

const useWeightLossDiscountNewPrice = () =>
  useSelector<any, string | undefined>(
    (state) => state.config.weightLossDiscount?.newPrice
  )

const useWeightLossSetupHomeInfo = () =>
  useSelector<any, string>((state) => state.config.weightLossSetupHomeInfo)

const useWeightLossEnabledForGPAtHand = () =>
  useSelector<any, boolean>(
    (state) => state.config.weightLossEnabledForGPAtHand
  )

const useWeightLossSetupNextStepsInfo = () =>
  useSelector<any, string>((state) => state.config.weightLossSetupNextStepsInfo)

const useWeightLossSetupConfirmationInfo = () =>
  useSelector<any, string>(
    (state) => state.config.weightLossSetupConfirmationInfo
  )

const useWeightLossCheckInHomeInfo = () =>
  useSelector<any, string>((state) => state.config.weightLossCheckInHomeInfo)

const useWeightLossRefillHomeInfo = () =>
  useSelector<any, string>((state) => state.config.weightLossRefillHomeInfo)

const useWeightLossRefillNextStepsInfo = () =>
  useSelector<any, string>(
    (state) => state.config.weightLossRefillNextStepsInfo
  )

const useWeightLossRefillConfirmationInfo = () =>
  useSelector<any, string>(
    (state) => state.config.weightLossRefillConfirmationInfo
  )

const useWeightLossPercentage = () =>
  useSelector<any, string | undefined>(
    (state) => state.config.weightLossPercentage
  )

const useQuestionnairesEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.questionnaires?.enabled)

const useWellnessEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.wellness?.enabled)

const useBloodTestEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.bloodTest?.enabled)

const useBloodTestCheckoutEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.bloodTest?.checkout?.enabled
  )

const useUtiTestEnabled = () =>
  useSelector<any, boolean>((state) => state.config.utiTest?.enabled)

const useHiddenConsumerNetworkNames = () =>
  useSelector<any, string[]>((state) =>
    state.config.hiddenConsumerNetworkNames
      ? JSON.parse(state.config.hiddenConsumerNetworkNames)
      : []
  )

const useEnabledCNsForSubscriptions = () =>
  useSelector<any, string[]>((state) => state.config.subscriptionsEnabledForCns)

const useDisclaimerCNsForSubscriptions = () =>
  useSelector<any, string[]>(
    (state) => state.config.subscriptionsWithDisclaimerForCns
  )

type InstantAppointmentsConfig = {
  profession?: string
  professionKey?: string
  professionText?: string
  timeWindowMinutes?: number
  startTimeDelayMinutes?: number
  enabled?: boolean
}

const useInstantAppointmentsConfig = () =>
  useSelector(
    (state) =>
      (state as any).config.instantAppointments as
        | InstantAppointmentsConfig
        | undefined
  )

const useAppointmentBookingFlowsConfig = () =>
  useSelector<ReduxState, AppointmentBookingFlowsConfig | undefined>(
    (state) => state.config.appointment?.booking?.bookingFlows
  )

const usePartnerBookingFlowEnabled = () =>
  useAppointmentBookingFlowsConfig()?.stepsConfig?.defaultStepsKey ===
  'partners'

const useBookingSkipSelectPatientScreenEnabled = () =>
  useSelector<ReduxState, boolean>(
    (state) => !!state.config.appointment?.booking?.skipSelectPatientScreen
  )

const useEmbeddedViewModeEnabled = () =>
  useSelector<ReduxState, boolean>(
    (state) => !!state?.config.embeddedViewMode?.enabled
  )

const useServiceNowChatEnabled = () =>
  useSelector<any, boolean>((state) => !!state.config.serviceNowChat?.enabled)

const useAuthenticatedServiceNowChatEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.serviceNowAuthenticatedChat?.enabled
  )

const useAuthenticatedSnowEntryPointEnabled = () =>
  useSelector<any, boolean>(
    (state) => !!state.config.serviceNowAuthenticatedChat?.entryPointEnabled
  )

const useContactUsUrl = () =>
  useSelector<any, string>((state) => state.config.supportContactUsUrl)

export {
  useRemoteConfigLoaded,
  usePoweredByEnabled,
  useSupportPhoneNumber,
  useAppointmentsEnabled,
  useAppointmentsCovidBannerEnabled,
  useBookAppointmentEnabled,
  useClientCompatibleFlowsCheckInEnabled,
  useClientCompatibleFlowsRtmEnabled,
  useLocationCheckEnabled,
  useServiceMixBookingFlowConfig,
  useServiceMixBookingFlowEnabled,
  useServiceMixBookingFlowFiltersEnabled,
  useVideoCallWithToken,
  useBabylonBrandingEnabled,
  useEnvironment,
  selectEnvironment,
  useCustomerSupportEnabled,
  useDownloadAppEnabled,
  useReferAFriendEnabled,
  useFacebookEnabled,
  useFeedbackEnabled,
  useMarketingEnabled,
  useNotificationsEnabled,
  useFamilyEnabled,
  useMembershipPlansEnabled,
  usePaymentEnabled,
  usePersonalDetailsEnabled,
  useAllergiesEnabled,
  useGpDetailsEnabled,
  useMedicalHistoryEnabled,
  useMedicationsEnabled,
  usePharmaciesEnabled,
  usePrescriptionsEnabled,
  useTestAndKitsEnabled,
  useChatHistoryEnabled,
  useAuth0Config,
  useIdVerificationEnabled,
  usePrivacyPolicyEnabled,
  useTermsAndConditionsEnabled,
  useFooterCookiesEnabled,
  useCookiesEnabled,
  usePartnerLogo,
  useCookiePolicyLink,
  useCurrentUser,
  useCurrentUserId,
  useAppTitle,
  useDefaultRegion,
  usePersonalDetailsAddressEnabled,
  useAppName,
  useDefaultLanguage,
  useLogo,
  useRegistrationFields,
  useHealthcareIdentifierModalShown,
  useRegisterShowPromotionModal,
  useRegisterPromocode,
  useLocalisation,
  useDismissInvite,
  useAppointmentConfig,
  useMultipleImagesBooking,
  selectBookAppointmentsEnabled,
  selectAppName,
  selectFamilyEnabled,
  useInviteBookingFlowConfig,
  selectInviteBookingFlowConfig,
  selectBookingInvitation,
  selectLocalisation,
  selectDefaultLanguage,
  selectLogo,
  useFeatureFlags,
  useInCall,
  useIncomingCall,
  useRegulatoryEnabled,
  useRegulatoryPolicyLink,
  useChatbotEnabled,
  useOnboarding,
  useMissingProfileFields,
  useUnderMaintenance,
  useHealthInformationEnabled,
  useHealthInformationLink,
  useWebSignupUrl,
  useAppUrl,
  useOnboardingRedirects,
  useFamilyMemberUrl,
  useReferralPdfDownload,
  useTriggerDocumentDownloadInExistingTab,
  useServiceUrl,
  useGpahRegistrationRestricted,
  useGpahRegistrationRestrictedCity,
  useGpahRegistrationRestrictedDistricts,
  useGpahRegistrationRestrictedPostcodePrefixes,
  useBloodTestSetupNextStepsInfo,
  useIsAutomatedCheckInEnabledForScreen,
  useIsWeightLossAutomatedCheckInEnabled,
  useWeightLossDiscountCode,
  useWeightLossDiscountCodeValidUntil,
  useWeightLossDiscountAmount,
  useWeightLossDiscountNewPrice,
  useWeightLossImmediatePayment,
  useWeightLossPrice,
  useWeightLossSetupEnabled,
  useWeightLossNoProctorEnabled,
  useWeightLossNoProctorStartupEnabled,
  useWeightLossSetupHomeInfo,
  useWeightLossSetupNextStepsInfo,
  useWeightLossEnabledForGPAtHand,
  useWeightLossSetupConfirmationInfo,
  useWeightLossCheckInHomeInfo,
  useWeightLossRefillHomeInfo,
  useWeightLossRefillNextStepsInfo,
  useWeightLossRefillConfirmationInfo,
  useWelcomePageUpgradeButtonEnabled,
  useWelcomePageWeightManagementButtonEnabled,
  useWelcomePageBookInMinutesButtonEnabled,
  useWelcomePageBookInMinutesMaxWaitTime,
  useWelcomePageDiscoverSectionEnabled,
  useBloodTestCheckoutEnabled,
  useBloodTestEnabled,
  useWellnessEnabled,
  useWeightLossPercentage,
  useHiddenConsumerNetworkNames,
  useInstantAppointmentsConfig,
  useAppointmentBookingFlowsConfig,
  usePartnerBookingFlowEnabled,
  useEmbeddedViewModeEnabled,
  useBookingSkipSelectPatientScreenEnabled,
  useEnabledCNsForSubscriptions,
  useServiceNowChatEnabled,
  useQuestionnairesEnabled,
  useDisclaimerCNsForSubscriptions,
  useAuthenticatedServiceNowChatEnabled,
  useAuthenticatedSnowEntryPointEnabled,
  useContactUsUrl,
  useUtiTestEnabled,
}
