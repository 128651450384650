/* eslint-disable react/prop-types */
import React from 'react'
import classNames from 'classnames/bind'
import styles from './Card.module.scss'

const cx = classNames.bind(styles)

type Props = {
  title: string
  description: string
  image?: string
  imageDescription?: string
  children?: React.ReactNode
}

const Card: React.FC<Props> = ({
  title,
  description,
  image,
  imageDescription,
  children,
}) => (
  <div className={cx('card')}>
    {image && <img src={image} alt={imageDescription} />}
    <div>
      <h6>{title}</h6>
      <p>{description}</p>
      {children && <div>{children}</div>}
    </div>
  </div>
)

export default Card
